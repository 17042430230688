<template>
  <div class="landingPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div v-if="total > 0" class="row">
          <div class="col text-right">
            <label class="d-inline-flex align-items-center">
              <b-form-input
                  v-model="query"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
            <button
                class="ml-1 btn btn-dark-blue btn-sm"
                @click="getSearches"
            >
              <i class="uil uil-search-alt"></i>
            </button>
          </div>
        </div>
        <div v-if="total > 0" class="card rounded-lg">
          <div class="card-body">
            <h5 class="mt-0">Local Lead Searches</h5>
            <div v-if="searches.length" class="tablerounededCorner">
            <table class="rounded-3 table table-striped roundedTable mb-0">
              <thead>
              <tr>
                <th>Date</th>
                <th>Keyword</th>
                <th>City</th>
                <th>Letter Template</th>
                <th>Landing Page</th>
                <th>Leads</th>
                <th>QR</th>
                <th>Leads</th>
                <th style="width: 220px;"></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="search in searches" :key="search.id">
                  <td>{{search.front_date}}</td>
                  <td>{{search.keyword}}</td>
                  <td>{{search.city}}</td>
                  <td>
                    {{search.letter.name}}
                  </td>
                  <td>
                    {{search.page.name}}
                  </td>
                  <td>
                    <span v-if="search.leads">{{search.leads.length}}</span>
                    <span v-else>0</span>
                  </td>
                  <td class="font-weight-bold">
                      <span class="text-dark-blue">{{search.stats.total_qr}}</span>
                      <span class="ml-3 text-success">{{search.stats.percent_qr}}%</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="text-dark-blue">{{search.stats.total_leads}}</span>
                    <span class="ml-3 text-success">{{search.stats.percent_leads}}%</span>
                  </td>
                  <td style="width: 210px;">
                    <router-link :to="{ name: 'agency.campaign-leads', params: { id: search.id } }" class="btn btn-sm btn-success">View Leads</router-link>
                    <b-button size="sm" variant="light" class="ml-2 text-white" style="background: #a6a6a6;" @click="printLetter(search)">Reprint Letters</b-button>
                  </td>
                </tr>

              </tbody>
            </table>
            </div>
            <div v-else>No Leads</div>
            <div v-if="total > 0 && searches.length && total > searches.length" class="text-center">
              <b-button size="sm" variant="primary" class="mt-4" @click="showMore">Show More</b-button>
            </div>
          </div>
          <b-modal v-model="selectedPrint.isPrinting" :title="selectedPrint.keyword + ', ' + selectedPrint.city + ' Letters'" :hide-footer="true" size="lg" @close="selectedPrint.isPrinting = false">
            <div class="text-center">
              <b-spinner v-if="pdfLoading" />
            </div>
            <embed ref="pdfEmbed" :src="pdfURL(selectedPrint.id)" width="100%" style="min-height: 75vh" @load="pdfLoading = false" />
            <b-button v-if="!pdfLoading" class="w-100 d-block mt-2" @click="downloadPDF">Download Letters</b-button>
          </b-modal>
        </div>
        <div v-else class="text-center mt-5">
          <b-spinner v-if="!hasLoaded" />
          <span v-else>After you print your letters they will show up here</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
      query:'',
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      page:1,
      total:0,
      pdfLoading:false,
      selectedPrint: {
        isPrinting:false,
        keyword:'',
        city:'',
        id:null
      },
      printLetters:false,
      searchForm:{
        keyword:null,
        lat:null,
        lng:null,
        city:null,
        agency_id:null,
        letter_id:null,
        page_id:null
      },
      apiLoaded:false,
      searches:[],
      letters:[],
      pages:[],
      hasLoaded:false
    }
  },
  computed: {

  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.searchForm.agency_id = this.user.agency.id;
    this.apiLoaded = true;
    this.getSearches();
  },
  methods: {
    showMore(){
      this.page += 1;
      this.getSearches();
    },
    downloadPDF(){
      const pdfURL = this.$refs.pdfEmbed.getAttribute('src');

      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', pdfURL + '?download');
      link.click();
      document.body.removeChild(link);
    },
    printLetter(letter){
      this.pdfLoading = true;
      this.selectedPrint = letter;
      this.selectedPrint.isPrinting = true;
    },
    pdfURL(letterId){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1/agencies/print-letters/' + letterId + '/letters.pdf';
    },
    getSearches(){
      this.$store
          .dispatch('agency/getSearches',{agency_id:this.user.agency.id,page:this.page,search:this.query,only_mailed:true})
          .then((resp) => {
            this.total = resp.data.total;
            this.searches = resp.data.data;
            this.letters = resp.letters;
            this.pages = resp.pages;
            this.hasLoaded = true;
            // resp.data.forEach(search => this.searches.push(search));
          })
          .catch((err) => {
            console.log(err);
          })
    }
  },
}
</script>
<style lang="scss">


</style>
